.contact-form-container {
  position: absolute;
  bottom: -100vh;
  transition: bottom 0.5s ease-in-out;
  z-index: 9;
  background: transparent;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #000;
  padding: 12px;
  border-radius: 3px;
  box-shadow: 12px 12px 0px #000;
}

form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-form-container.hidden {
  position: absolute;
  bottom: -100vh;
}

.contact-form-container.visible {
  position: absolute;
  bottom: 10vh;
}

input {
  width: 300px;
  height: 20px;
}

input:focus,
textarea:focus {
  outline: none;
}

input::placeholder {
  color: #808080;
}

textarea,
input {
  background-color: #fff;
  border: 1px solid #000000;
  padding: 2px 4px;
  font-size: 14px;
  color: #000000;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.3s,
    margin 0.3s;
  font-family: monospace;
  box-shadow: inset 1px 1px 2px #000000;
  box-shadow: #000 4px 4px 0px;
}

textarea:focus,
input:focus {
  margin: 4px -4px -4px 4px;
  box-shadow: none;
}

.submit-button {
  border: 1px solid #000000;
  transition: all 0.3s ease-in-out;
  background: #d5bacc;
  padding: 2px;
  border-radius: 4px;
  box-shadow: #000 4px 4px 0px;
}

.submit-button:disabled {
  background: #dcdcdc;
  color: #979797;
  box-shadow: #979797 4px 4px 0px;
}

.submit-button:hover:not(:disabled) {
  background-color: #fff;
  color: #d5bacc;
}

@media screen and (orientation: landscape) and (width < 1000px) {
  .contact-form-container.hidden {
    bottom: -150vh;
  }

  .contact-form-container.visible {
    bottom: 0;
    padding: 0 12px;
  }

  .contact-form-container .headline {
    font-size: 0.5rem;
  }

  form {
    gap: 4px;
  }

  input,
  textarea {
    width: 200px;
  }

  label {
    font-size: 0.75rem;
  }
}
