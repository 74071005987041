/* Portfolio.css */
.about-container.hidden {
  position: absolute;
  top: 200vh;
  height: 0;
  width: 100dvw;
}

.about-container.visible {
  position: absolute;
  top: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 10;
}

.about-container {
  position: absolute;
  top: 200vh;
  transition: all 0.5s ease-in-out;
  z-index: 9;
  background: #fff;
  display: flex;
  justify-content: center;
}

.basics {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
}

.basics p {
  text-wrap: pretty;
}

.about-content {
  width: 90%;
  max-width: 800px;
  height: 90%;
  overflow: scroll;
  align-self: center;
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  cursor: pointer;
}

.link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.experience-list,
.experience-entry {
  padding-left: 0;
}

.experience-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experience-entry p {
  font-size: 0.75rem;
}

.experience-list {
  margin-top: 0;
}

.experience-entry h5 {
  font-style: italic;
  background: rgba(249, 106, 177, 0.5);
  color: white;
  padding: 2px;
  font-size: 1rem;
}

.workplace-list {
  background: #ffffff70;
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.about-can {
  zoom: 50%;
}

/* Tooltip styles */
.project-container {
  position: relative;
}

.project-title {
  font-size: 1rem;
  color: #666;
  margin: 12px 0 8px 0;
  cursor: pointer;
  display: inline-block;
  padding: 2px 6px;
  transition: background-color 0.2s ease;
}

.project-title:hover {
  background-color: rgba(249, 106, 177, 0.1);
}

.tooltip-overlay {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.2s ease-out;
}

.tooltip-overlay img {
  max-width: 50vw;
  max-height: 70vh;
  width: auto;
  height: auto;
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Hide scrollbar */
.about-content::-webkit-scrollbar {
  display: none;
}

.about-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 600px) {
  .experience-entry {
    flex-direction: column;
    align-items: start;
  }

  .experience-entry p {
    margin-top: 0;
  }

  .experience-entry h5 {
    margin-bottom: 4px;
  }

  .tooltip {
    left: 50%;
    top: 100%;
    transform: translateX(-50%) scale(0);
  }

  .tooltip-container:hover .tooltip {
    transform: translateX(-50%) scale(1);
  }

  .about-can {
    zoom: 30%;
    width: 50%;
  }
}
